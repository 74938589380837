import { Link } from "gatsby";
//import PropTypes from "prop-types";
import React from "react";

const Sidebar = (props) => (

<div
    style={{
      border: '2px solid #e6e6e6',
      maxWidth: 960,
      padding: '0.5rem',
      marginBottom: '25px'
    }}
>
<strong>{props.title}</strong> 

  <div>
    {props.description}
    
  </div>
  <div className="links">
    <Link to={props.link}>{props.caption}</Link>
  </div>
</div>
);

export default Sidebar