import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import Sidebar from "../components/sidebar";

const IndexPage = (props) => {
  const postList = props.data.allMarkdownRemark;
  return (

    <Layout>
    <div className="post-list">
      {postList.edges.map(({ node }, i) => (
        <Link to={node.fields.slug} className="link" key ={i}>
          
            <h1 className="post-index-title">{node.frontmatter.title}</h1>
            <span className="post-index-date">{node.frontmatter.date}</span>
            <p className="post-index-excerpt">{node.excerpt}</p>
      
        </Link>
      ))}
        </div>
      <div className="sidebar-container" style={{float:"right"}}>
                <Sidebar
                  title="About Duanshu"
                  description="Despite the Chinese name, this is a personal Japanese-English translation blog."
                  link="/my-firstpost/"
                  caption="More Info Here."                                                                           
                  
                />
                <Sidebar
                  title="Current Projects"
                  link="/projects/"
                  caption="Click Here."
                  
                />
                
    </div>
    </Layout>
    
  )
}

export default IndexPage;
export const listQuery = graphql`
  query ListQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          fields{
            slug
          }
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM Do YYYY")
            title
          }
        }
      }
    }
  }
`